// Style for Input component `lib/components/form/input`.
@import 'shared_layout';

[i2gInput] {
  @include font-size(16px);
  color: $text-primary-color;
  padding: $user-input-field-vertical-padding spacing(6)
    $user-input-field-vertical-padding $user-input-field-vertical-padding;
  border: 1px solid $state-normal-color;
  box-sizing: border-box;
  width: 100%;
  caret-color: $state-focus-color;

  &[disabled],
  &[readonly] {
    opacity: $disabled-opacity;
    cursor: not-allowed;
  }

  &:focus,
  &.focus {
    border: 2px solid $state-focus-color;
    padding: (spacing(1) - 1px);
    padding: ($user-input-field-vertical-padding - 1px) (spacing(6) - 1px)
      ($user-input-field-vertical-padding - 1px)
      ($user-input-field-vertical-padding - 1px);
  }

  &.ng-dirty.ng-invalid {
    border-color: $state-alert-color;
    box-shadow: 0 0 0 1px $state-alert-color inset;
  }

  &.value-alignment-center {
    text-align: center;
  }

  &.value-alignment-right {
    text-align: right;
  }
}

input[i2gInput] {
  height: spacing(6);
}
