@import 'shared_animations';

#app-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100vh;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;

  .spinner {
    width: 80px;
    height: 80px;

    @include loadingSpinner;
  }

  .i2g-logo {
    height: 150px;
    margin-left: 15px;
  }
}

body.loaded {
  #app-loading {
    display: none;

    .spinner {
      animation-name: none;
    }
  }
}
