@import 'shared_layout';
@import 'shared_animations';
@import 'shared_colors';

$disabled-opacity: 0.5;
$secondary-button-padding: spacing(1);

$default-font-sizes: (
  10px: 10px,
  12px: 12px,
  13px: 13px,
  14px: 14px,
  15px: 15px,
  16px: 16px,
  18px: 18px,
  20px: 20px,
  22px: 22px,
  24px: 24px,
  26px: 26px,
  28px: 28px,
  32px: 32px,
  36px: 36px,
  48px: 48px,
);

@function line-height($font-size: map-get($default-font-sizes, 16px)) {
  @return ceil($font-size * 1.2);
}

@function line-heights($font-size, $lines: 1) {
  @return $lines * line-height($font-size);
}

@mixin font-size($font-size: 16px) {
  @if map-has-key($default-font-sizes, $font-size) {
    font-size: map-get($default-font-sizes, $font-size);
    line-height: line-height($font-size);
  } @else {
    @warn 'There is no default font-size "#{ $font-size }", choose one from #{ $default-font-sizes }';
  }
}

@mixin box-shadow {
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
}

@mixin no-box-shadow {
  box-shadow: none;
}

@mixin common-primary-button(
  $background-color,
  $font-color: $text-reversed-color,
  $font-size: 16px,
  $min-width: 0,
  $min-height: 48px
) {
  font-size: $font-size;
  font-weight: 800;
  color: $font-color;
  padding: 0 spacing(3);
  box-sizing: border-box;
  min-width: $min-width;
  min-height: $min-height;
  white-space: nowrap;
  background-color: $background-color;

  &:hover,
  &:focus,
  &:active {
    @include box-shadow;
    color: $font-color;
  }

  &:active,
  &:focus {
    opacity: 0.9;
  }

  &[disabled] {
    background-color: $action-disabled-color;
    color: $text-secondary-color;
    opacity: 1;
    box-shadow: none;
  }
}

@mixin common-secondary-button($font-color, $font-size: 16px) {
  font-size: $font-size;
  line-height: ceil($font-size * 1.2);
  font-weight: 800;
  color: $font-color;
  padding: $secondary-button-padding;
  white-space: nowrap;

  @include no-box-shadow;
  background-color: transparent;

  &:hover,
  &:focus,
  &:active {
    @include no-box-shadow;
    color: $font-color;
    opacity: 0.9;
    background-color: transparent;
  }

  &[disabled] {
    opacity: 1;
    color: $action-disabled-color;
    background-color: transparent;
  }
}

@mixin common-tertiary-button(
  $font-color: $action-tertiary-color,
  $font-size: 16px,
  $min-width: 140px
) {
  @include common-secondary-button($font-color, $font-size);
  // TODO: replace common secondary button with this style
  display: flex;
  align-items: center;
  padding: 0;
  min-height: 48px;
  min-width: $min-width;
}

// == START BUTTON DESIGN SYSTEM

@mixin default-button() {
  @include common-primary-button(
    $background-primary-color,
    $text-secondary-color
  );
  border: solid 1px $action-secondary-color;
  font-weight: 800;
}

@mixin default-reversed-button() {
  @include common-primary-button($action-secondary-color);
  border: solid 1px $action-reversed-color;
  font-weight: 800;
}

/**
 * using new name emphasis instead of primary
 */
@mixin emphasis-button() {
  @include common-primary-button(
    $background-color: $action-primary-color,
    $font-color: $text-reversed-color,
    $font-size: 16px,
    $min-width: 140px
  );
}

@mixin alert-button() {
  @include common-primary-button(
    $background-color: $state-alert-color,
    $font-color: $text-reversed-color,
    $font-size: 16px,
    $min-width: 140px
  );
}

@mixin tertiary-button() {
  @include common-tertiary-button();

  .pull-left {
    width: 100%;
    text-align: left;
  }

  .pull-right {
    width: 100%;
    text-align: right;
  }
}

@mixin tertiary-alert-button() {
  @include common-tertiary-button(
    $font-color: $state-alert-color,
    $font-size: 16px,
    $min-width: 140px
  );
}

// == END BUTTON DESIGN SYSTEM

// == START OLD BUTTON

// These buttons below should be removed once design system work is complete

@mixin primary-button(
  $font-size: 16px,
  $min-width: 140px,
  $font-color: $text-reversed-color
) {
  @include common-primary-button(
    $background-color: $action-primary-color,
    $font-color: $font-color,
    $font-size: $font-size,
    $min-width: $min-width
  );
}

@mixin primary-cancel-button($font-size: 16px, $min-width: 140px) {
  @include common-primary-button(
    $background-color: $background-secondary-color,
    $font-color: $text-primary-color,
    $font-size: $font-size,
    $min-width: $min-width
  );
}

@mixin primary-delete-button($font-size: 16px, $min-width: 140px) {
  @include common-primary-button(
    $background-color: $support-alert-color,
    $font-color: $text-reversed-color,
    $font-size: $font-size,
    $min-width: $min-width
  );
}

@mixin secondary-button($font-size: 16px) {
  @include common-secondary-button(
    $font-color: $action-tertiary-color,
    $font-size: $font-size
  );
}

@mixin secondary-cancel-button($font-size: 16px) {
  @include common-secondary-button(
    $font-color: $action-secondary-color,
    $font-size: $font-size
  );
}

@mixin secondary-delete-button($font-size: 16px) {
  @include common-secondary-button(
    $font-color: $text-alert-color,
    $font-size: $font-size
  );
}

// == END OLD BUTTON

@mixin system-default-font() {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Lato', sans-serif;
}

@mixin user-select($value) {
  -webkit-touch-callout: $value;
  -webkit-user-select: $value;
  -khtml-user-select: $value;
  -moz-user-select: $value;
  -ms-user-select: $value;
  user-select: $value;
}

@mixin scrollbar() {
  overflow: auto;

  //smoothens the scrolling on iOS safari browser
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

@mixin safari-full-height() {
  _::-webkit-full-page-media,
  _:future,
  :root :host {
    // ios safari browser hack
    // @see https://stackoverflow.com/questions/2860745/css-hack-for-safari-only
    // Chrome actually supports `-webkit-fill-available` but it is displayed differently in Chrome
    height: -webkit-fill-available;
  }
}

@mixin overlay() {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $background-overlay-color;
}

$user-input-field-border-width: 1px;
$user-input-field-border-color: $state-normal-color;
$form-label-size: 155px;
$form-label-value-padding: spacing(2);
$form-value-size: 310px;
$form-field-line-height: 18px;
$form-field-min-height: 42px;
$toggle-btn-width: 34px;
$toggle-label-left-padding: 20px;
$select-field-min-width: 150px;
$checkbox-size: 20px;
$checkbox-label-padding-left: $checkbox-size + spacing(2);

@mixin form-label-size {
  flex: 0 0 $form-label-size;
}

@mixin form-label-common {
  @include font-size(16px);
  font-weight: normal;
  color: $text-primary-color;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;

  &.disabled {
    opacity: $disabled-opacity;
    cursor: not-allowed;
  }
}

/**
 * Used to style label in row (horizontal) layout
 */
@mixin form-label {
  @include form-label-common;
  @include form-label-size;

  padding-top: spacing(2);
  padding-right: $form-label-value-padding;
}

/**
 * Used to style label in column (vertical) layout
 */
@mixin form-label-column-layout {
  @include form-label-common;
  flex: 0;
  margin-bottom: spacing(1);
}

@mixin right-aligned-form-label {
  :host {
    &[data-alignment='right'] {
      .field-label {
        text-align: right;
        justify-content: flex-end;
      }
    }

    &[data-alignment='center'] {
      .field-label {
        text-align: center;
        justify-content: center;
      }
    }

    &[data-alignment='left'] {
      .field-label {
        text-align: left;
        justify-content: flex-start;
      }
    }
  }
}

// TODO: do we still need condensed?
@mixin condensed-input-field {
  &.condensed {
    &:not(.has-placeholder) {
      &.has-value {
        .field-label {
          font-weight: normal;
        }
      }

      .field-label {
        font-weight: bold;
        cursor: text;
      }
    }

    &.has-placeholder {
      &.has-value {
        .field-label {
          visibility: visible;
          opacity: 1;
        }
      }

      .field-label {
        visibility: hidden;
        opacity: 0;
      }
    }

    &.has-placeholder,
    &.has-value {
      .field-label {
        @include font-size(12px);
        bottom: 100%;
        transform: translateY(50%);
        color: $text-primary-color;
        cursor: default;
        padding-top: 0;
      }
    }

    .field-label {
      position: absolute;
      left: $user-input-field-horizontal-padding +
        $user-input-field-border-width;
      color: $text-placeholder-color;
      transform: translateZ(0);
      z-index: z('form-field-label');
    }

    .description {
      margin-left: $user-input-field-horizontal-padding +
        $user-input-field-border-width;
    }
  }
}

@mixin input-field {
  display: flex;
  position: relative;
  max-width: 100%;
  width: $form-label-size + $form-label-value-padding + $form-value-size;

  .input-label {
    @include form-label;
  }

  &.has-column-layout {
    flex-direction: column;

    .input-label {
      @include form-label-column-layout;
    }
  }

  &:focus-within .input-label {
    font-weight: bold;
  }

  @include for-size(mobile) {
    width: 100%;
  }
}

@mixin auto-complete {
  .sey-container {
    left: 0 !important;
    top: calc(100% + 5px) !important;
    width: 100%;
    border-radius: 2px;

    z-index: z('dropdown');

    .category-header {
      display: flex;
      align-items: center;

      padding: 15px 20px 0;
    }

    .category-label {
      color: $text-secondary-color;
    }

    .category-divider {
      flex: 1 1 auto;
      margin-left: spacing(2);

      border-style: solid;
      border-color: $border-subtle-color;
    }

    .sey-list {
      margin-top: spacing(1);
      padding: 0 0 spacing(1);
      max-height: 400px;
      overflow: auto;
    }

    .sey-item {
      &:not(.sey-hide) {
        display: flex;
      }

      padding: 7px 20px;
      color: $text-secondary-color;

      white-space: normal;
      text-overflow: initial;

      &:hover:not(.sey-selected) {
        background-color: transparent;
      }
    }

    .sey-selected {
      background-color: $background-secondary-color;
      color: $text-primary-color;
    }
  }
}
