@import 'shared_layout';

@mixin hardware($backface: true) {
  @if $backface {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}

@mixin animation(
  $duration,
  $animation,
  $delay: 0s,
  $fill-mode: none,
  $timing-function: ease-out
) {
  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-fill-mode: $fill-mode;
  animation-timing-function: $timing-function;

  @include hardware;
}

@mixin fadeIn($delay: 0s, $duration: 0.3s, $fill-mode: none) {
  @include animation(
    $duration: $duration,
    $animation: fadeIn,
    $delay: $delay,
    $fill-mode: $fill-mode,
    $timing-function: ease-out
  );
}

@mixin fadeOut($delay: 0s, $duration: 0.3s) {
  @include animation(
    $duration: $duration,
    $animation: fadeOut,
    $delay: $delay,
    $timing-function: ease-out
  );
}

@mixin slideFromLeft($delay: 0s, $duration: 0.3s) {
  @include animation(
    $duration: $duration,
    $animation: slideFromLeft,
    $fill-mode: forwards,
    $delay: $delay,
    $timing-function: ease-out
  );
}

@mixin slideFromRight($delay: 0s, $duration: 0.3s) {
  @include animation(
    $duration: $duration,
    $animation: slideFromRight,
    $fill-mode: forwards,
    $delay: $delay,
    $timing-function: ease-out
  );
}

@mixin slideFromBottom($delay: 0s, $duration: 0.3s) {
  @include animation(
    $duration: $duration,
    $animation: slideFromBottom,
    $fill-mode: forwards,
    $delay: $delay,
    $timing-function: ease-out
  );
}

@mixin rotate180($delay: 0s, $duration: 0.3s, $fill-mode: forwards) {
  @include animation(
    $duration: $duration,
    $animation: rotate180,
    $delay: $delay,
    $fill-mode: $fill-mode,
    $timing-function: ease-out
  );
}

@mixin placeholderShimmer() {
  background-color: $background-secondary-color;

  @include animation(
    $duration: 1.5s,
    $animation: shimmer,
    $fill-mode: forwards,
    $timing-function: ease-out
  );
  animation-iteration-count: infinite;
}

@mixin loadingSpinner() {
  color: $support-brand-color;
  animation-name: loadingSpinner;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
