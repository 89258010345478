// Do not alter START_REGION and END_REGION comment, it is used to generate Storybook stories
// @see scripts/color-stories.ts

//START_REGION:COLOR_NAME

$olive-color: var(--olive-color);
$blue-color: var(--blue-color);
$red-color: var(--red-color);
$orange-color: var(--orange-color);
$gold-color: var(--gold-color);
$black-color: var(--black-color);
$slate-color: var(--slate-color);
$alpha-slate-color: var(--alpha-slate-color);
$storm-color: var(--storm-color);
$ghost-color: var(--ghost-color);
$fog-color: var(--fog-color);
$pure-white-color: var(--pure-white-color);

// END_REGION:COLOR_NAME

// START_REGION:COLOR_SEMANTIC

$background-primary-color: $pure-white-color;
$background-secondary-color: $fog-color;
$background-reversed-color: $slate-color;
$background-overlay-color: $alpha-slate-color;

$divider-regular-color: $ghost-color;
$divider-reversed-color: $storm-color;
$divider-heavy-color: $storm-color;

$state-normal-color: $storm-color;
$state-focus-color: $blue-color; // indicates the input component is focused (careful not to confuse it with $border-selected-color)
$state-alert-color: $red-color;
$state-disabled-color: $ghost-color;

$action-primary-color: $blue-color;
$action-secondary-color: $storm-color;
$action-disabled-color: $ghost-color;
$action-tertiary-color: $blue-color;
$action-reversed-color: $pure-white-color;

$icon-highlight-color: $blue-color;
$icon-regular-color: $slate-color;
$icon-display-color: $storm-color;
$icon-reversed-color: $pure-white-color;
$icon-success-color: $blue-color;

$support-success-color: $olive-color;
$support-alert-color: $red-color;
$support-information-color: $blue-color;
$support-neutral-color: $storm-color;
$support-premium-color: $gold-color;
$support-brand-color: $orange-color;

$text-primary-color: $black-color;
$text-secondary-color: $storm-color;
$text-hint-color: $storm-color;
$text-placeholder-color: $ghost-color;
$text-reversed-color: $pure-white-color;
$text-alert-color: $red-color;

$border-heavy-color: $storm-color;
$border-subtle-color: $fog-color;
$border-selected-color: $orange-color; // indicates what items of a list the user has selected

// END_REGION:COLOR_SEMANTIC
