// You can add global styles to this file, and also import other style files
@import 'reset';
@import 'shared_colors';
@import 'shared_ui_helpers';
@import 'shared_layout';
@import 'shared_animations';
@import 'button';
@import 'input';
@import 'styles-loading';

[hidden] {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

// Hide the content with care for A11y
.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

/** New Font **/
@font-face {
  font-family: ProjectFont;
  src: url(../assets/fonts/Project-Regular.woff2) format('woff2'),
    url(../assets/fonts/Project-Regular.woff) format('woff');
}

@font-face {
  font-family: ProjectFont;
  src: url(../assets/fonts/Project-Medium.woff2) format('woff2'),
    url(../assets/fonts/Project-Medium.woff) format('woff');
  font-weight: 700;
}

@font-face {
  font-family: ProjectFont;
  src: url(../assets/fonts/Project-Bold.woff2) format('woff2'),
    url(../assets/fonts/Project-Bold.woff) format('woff');
  font-weight: 800;
}

@font-face {
  font-family: ProjectFont;
  src: url(../assets/fonts/Project-Black.woff2) format('woff2'),
    url(../assets/fonts/Project-Black.woff) format('woff');
  font-weight: 900;
}

html,
button,
input,
select,
textarea {
  color: $text-primary-color;
  font-family: ProjectFont, sans-serif !important;
}

body {
  @include font-size(16px);
  display: flex;
  min-height: 100vh;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;

  --olive-color: #1b8559;
  --blue-color: #324bff;
  --red-color: #e80e51;
  --orange-color: #ff4b00;
  --gold-color: #ffc832;
  --black-color: #0c0c0d;
  --slate-color: #2b2c2e;
  --alpha-slate-color: rgba(22, 45, 61, 0.8);
  --storm-color: #565a5c;
  --ghost-color: #d7dadb;
  --fog-color: #f5f5f6;
  --pure-white-color: #ffffff;
}

strong {
  font-weight: bold;
}

input,
textarea {
  padding: 0;
  margin: 0;
  border: none;

  &:focus {
    outline: none;
  }

  // Chrome/Opera/Safari
  &::-webkit-input-placeholder {
    color: $text-hint-color !important;
  }

  // Firefox 19+
  &::-moz-placeholder {
    color: $text-hint-color !important;
  }

  //IE 10+
  &:-ms-input-placeholder {
    color: $text-hint-color !important;
  }

  // Firefox 18-
  &:-moz-placeholder {
    color: $text-hint-color !important;
  }
}

input::-webkit-credentials-auto-fill-button,
input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}

textarea {
  resize: none;
}

// TODO: this should be moved to _button.scss
// and .router-link should be removed, it should use button directive for
// button styling
button,
.router-link {
  border-radius: 8px;
}

button {
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;

  transition: all 0.2s ease-out;

  &:focus,
  &:active {
    outline: 0;
  }
}

a {
  color: $action-tertiary-color;

  &,
  &:hover {
    text-decoration: none;
  }
}

a,
button,
label {
  &[disabled] {
    cursor: not-allowed !important;
    opacity: $disabled-opacity;
  }
}

.underline {
  text-decoration: underline;
}

.sr-text {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
}

.shimmer {
  @include placeholderShimmer;
  height: 100%;
}

.loading-spinner {
  @include loadingSpinner;
}

.checkmark {
  color: $icon-success-color !important;
}

@keyframes loadingSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes shimmer {
  0% {
    background-color: $background-secondary-color;
  }

  50% {
    background-color: $background-secondary-color;
    opacity: 0.9;
  }

  100% {
    background-color: $background-secondary-color;
  }
}

@keyframes fadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }

  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    visibility: visible;
    opacity: 1;
  }

  100% {
    visibility: hidden;
    opacity: 0;
  }
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideFromRight {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes slideFromBottom {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes rotate180 {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(180deg);
  }
}

// Used inside translated strings
.text-bold {
  font-weight: bold;
}

@mixin sssss() {
  border: 100px solid black;
}
