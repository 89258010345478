// Style for Button component `lib/components/button`
// TODO:
// - find a way to put this style inside `button` folder instead of here
// - ideally, button style should be here not in `shared_ui_helpers.scss`,
//   got an issue with `router-link.component.ts` to use i2gButton so need
//   use same button css style from `shared_ui_helpers`.
@import 'shared_ui_helpers';
@import 'shared_layout';

[i2gButton] {
  display: flex;
  align-items: center;
  justify-content: center;

  &.button-style-default {
    @include default-button();
  }

  &.button-style-default-reversed {
    @include default-reversed-button();
  }

  &.button-style-emphasis {
    @include emphasis-button();
  }

  &.button-style-alert {
    @include alert-button();
  }

  &.button-style-tertiary {
    @include tertiary-button();
  }

  &.button-style-tertiary-alert {
    @include tertiary-alert-button();
  }

  // Icon should be put before the text. If we support icon after text,
  // the implementation need to be modified.
  i2g-icon {
    margin-right: spacing(1);
  }
}

a[i2gButton] {
  text-decoration: none;
  color: $text-primary-color; // override the default blue
}
